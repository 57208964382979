import React from 'react';
import Content from './Content';
import PageVisual from './PageVisual';
import pageVisual from '../static/page-visuals/img-endokrinologie-03.jpg';
import ContentContainer from './ContentContainer';

const Home: React.FC = () => (
  <>
    <PageVisual img={pageVisual}/>
    <ContentContainer>
      <Content/>
    </ContentContainer>
  </>
);

export default Home;
