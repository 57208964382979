import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import SidebarContainer from './components/SidebarContainer';
import MainContainer from './components/MainContainer';
import './App.css';

const App: React.FC = () => (
  <Router>
    <div className="app">
      <SidebarContainer/>
      <MainContainer/>
    </div>
  </Router>
);

export default App;
