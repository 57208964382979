import React, { useState } from 'react';
import classnames from 'classnames';
import './PageVisual.css';

type Props = {
  img: string;
}

function PageVisual({img}: Props) {
  const [wide, setWide] = useState(false);

  return (
    <div className={classnames('page-visual', {
      'page-visual--bounded': wide,
    })} onClick={() => setWide(current => !current)}>
      <img src={img} alt=""/>
    </div>
  );
}

export default PageVisual;
