import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Nav from './Nav';
import './SidebarContainer.css';

const SidebarContainer: React.FC = () => {
  const isHome = useRouteMatch('/')?.isExact === true;

  return (
    <div className={`sidebar-container ${isHome ? '' : 'sidebar-container--wide'}`}>
      <div className="sticky-top">
        <Nav/>
      </div>
    </div>
  );
};

export default SidebarContainer;
