import React from 'react';
import { Route, Switch } from 'react-router-dom';
import WithSubNav from './WithSubNav';
import Home from './Home';
import './MainContainer.css';

const MainContainer: React.FC = () => (
  <div className="main-container">
    <Switch>
      <Route path="/with-sub-nav">
        <WithSubNav/>
      </Route>

      <Route path="/">
        <Home/>
      </Route>
    </Switch>
  </div>
);

export default MainContainer;
