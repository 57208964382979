import React, { CSSProperties, useEffect, useState } from 'react';
import './ContentContainer.css';

const note: CSSProperties = {
  color: '#999',
  fontSize: '.85rem',
  borderBottom: '1px solid #ccc',
  paddingBottom: '1rem',
}

const ContentContainer: React.FC = ({children}) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    let onResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div className="content-container">
      <p style={note}>{width}px</p>
      {children}
    </div>
  );
};

export default ContentContainer;
