import React from 'react';
import { NavLink } from 'react-router-dom';
import './Nav.css';

function Nav() {
  return (
    <nav className="Nav">
      <NavLink exact to="/">Home</NavLink>
      <NavLink exact to="/with-sub-nav">With sub nav</NavLink>
    </nav>
  );
}

export default Nav;
